import React, { useState, Component } from 'react';
import './App.css';
import YouTube from 'react-youtube';

function ChangeButton(props) {
    const changePrevVideo = function changePrevVideo() {
        if (props.Value <= 0) {
            props.setValue(0);
        } else {
            props.setValue(props.Value - 1);
        }
    };
    const changeNextVideo = function changeNextVideo() {
        if (props.Value >= 5) {
            props.setValue(5);
        } else {
            props.setValue(props.Value + 1);
        }
    };
    return (
        <div>
            <button onClick={changePrevVideo}>이전으로</button>
            <button onClick={changeNextVideo}>다음으로</button>
        </div>
    );
}

function Player() {
    const [videoIdIndex, setVideoIdIndex] = useState(0);
    const Ids = ['00yJy7W0DQE', 'qocQ7ekeMI4', 'fE4t2Ovgp-0', 'GOiCobCh2Ig', 'jAFogNao6CA', 'uab4P-0Gpzk'];

    const changeNextVideo = function NextVideo() {
        if (videoIdIndex >= 5) {
            setVideoIdIndex(5);
        } else {
            setVideoIdIndex(videoIdIndex + 1);
        }
    };
    return (
        <div className="player">
            <YouTube
                videoId={Ids[videoIdIndex]}
                onEnd={changeNextVideo}
                opts={{
                    playerVars: {
                        autoplay: 1,
                    },
                }}
            ></YouTube>
            <ChangeButton Value={videoIdIndex} setValue={setVideoIdIndex} />
        </div>
    );
}

class App extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="App">
                <Player></Player>
            </div>
        );
    }
}

export default App;